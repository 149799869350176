import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import xIcon from "../../assets/images/Products-images/X-black.png";
import advntgImg from "../../assets/images/Products-images/neux-advantages.png";
import productLogo from "../../assets/images/Products-images/neuxlogo.png";
import bannerImg from "../../assets/images/Products-images/nuex-banner.jpg";

import Footer from "../../component/Footer";
import Header from "../../component/Header";
import IntegrationSection from "../../component/IntegrationSection";
import { routes_path } from "../../config/routesPath";
import { neuxData } from "./productDetailsData";

const NeuX = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "NeuX - Touras";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Header xIcon={xIcon} productLogo={productLogo} />
      <div>
        <div className="md:hidden block">
          <img src={bannerImg} className="h-[200px] object-cover"/>
        </div>
        <div className="nuex-banner flex flex-col-reverse md:flex-row px-4 py-10 lg:py-28 md:px-10 relative font-MyriadPro">
          <div
            className="md:w-[60%]"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <p className="text-[24px] md:text-[45px] md:leading-[60px] font-bold text-primary">
              Power your business journey with neux's customized{" "}
              <span className="text-primary">digital solutions.</span>
            </p>
            <p className="font-light text-[14px] md:text-[18px] py-4 text-content max-w-[90%]">
              Digitalize your business through our neux platform for unmatched
              features, easy onboarding, and 24/7 transaction capabilities
              enabling end to end payments
            </p>
            <div className="grid grid-cols-2 gap-4 md:max-w-[60%] mt-3">
              <button
                className="text-white bg-primary py-2 md:py-1 px-6 text-[12px] md:text-[18px] rounded-[20px] hover:scale-95 transition-all"
                onClick={() => {
                  // window.open(routes_path?.signup);
                  navigate(routes_path?.contactUs);
                }}
              >
                Create Lead
              </button>
              <button
                className="text-primary border-[1px] bg-white text-[12px] md:text-[18px] border-primary py-2 md:py-1 px-6 rounded-[20px] hover:scale-95 transition-all"
                onClick={() => {
                  navigate(routes_path?.contactUs);
                }}
              >
                Contact Sales
              </button>
            </div>
          </div>
          {/* <div
            className="lg:w-[40%] h-full flex justify-center mt-10 md:mt-0 "
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <img src={bannerImg} className="h-auto" />
          </div> */}
        </div>

        <div className="py-4 text-white my-6 md:m-20">
          <p className="text-primary text-[24px] lg:text-[40px] px-4 lg:px-0 font-bold text-center lg:text-left">
            Advantages of having NeuX
          </p>
          <div className="flex flex-col lg:flex-row items-center gap-10 mx-4 lg:m-4">
            <div
              className="lg:w-[50%] h-full"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <img src={advntgImg} className="h-[300px] lg:h-auto" />
            </div>
            <div
              className="lg:w-[50%]"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="flex flex-col justify-between gap-10 p-10 bg-backgroundLight rounded-[20px]">
                <div className="flex items-start gap-4">
                  <p className="grid">
                    <span className="text-[16px] lg:text-[23px] font-bold mb-4 text-primary">
                      neux's comprehensive suite of modules, tailored to address
                      your specific business needs
                    </span>
                    <span className="text-[13px] lg:text-[17px] text-content">
                      Streamlined workflows for enhanced productivity. Automate
                      tedious operations with neux for speedy payments,
                      intelligent insights. Raising the sales and purchase
                      instruments for quicker payments
                    </span>
                  </p>
                </div>
                <div className="flex items-start gap-4">
                  <p className="grid">
                    <span className="text-[16px] lg:text-[23px] font-bold mb-4 text-primary">
                      Transform your business
                    </span>
                    <span className="text-[13px] lg:text-[17px] text-content">
                      Plug and Play with and for the solutions as needed for
                      Payments and Processes for SMEs/ MSMEs clubbed with HRMS
                      solutions and tax management to unlock the true potential
                      of neux.
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-center gap-4 mt-8">
                <button
                  className="text-white bg-primary py-2 lg:py-1 px-10 text-[12px] lg:text-[18px] rounded-[20px] hover:scale-95 transition-all"
                  onClick={() => {
                    // window.open(routes_path?.signup);
                    navigate(routes_path?.contactUs);
                  }}
                >
                  Create Account
                </button>
                <button
                  className="text-primary border-[1px] text-[12px] bg-white lg:text-[18px] border-primary py-2 lg:py-1 px-10 rounded-[20px] hover:scale-95 transition-all"
                  onClick={() => {
                    navigate(routes_path?.contactUs);
                  }}
                >
                  Contact Sales
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="py-10 bg-gradient-to-r from-[#FAEBEB] to-[#E2898533]">
          <div className="my-4 md:mx-4">
            <div className="grid gap-4 md:gap-10 p-4 md:p-8">
              <div className="flex justify-center items-center flex-col gap-3 md:gap-6 mb-4 md:mb-0">
                <p className="text-primary text-[24px] md:text-[38px] font-bold text-center px-4">
                  Shift Gears, tune in your Business for Innovation
                </p>
              </div>
              <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-10">
                {neuxData.map((item, index) => {
                  return (
                    <div
                      className="h-[300px] rounded-[20px] bg-[#FFFFFF] flex flex-col px-4 py-10 text-center md:text-left group transition-all duration-500"
                      key={index}
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <div className="h-max mx-auto md:mx-0 block group-hover:hidden">
                        <img src={item.imgUrl} className="h-max mx-auto" />
                      </div>
                      <p className="flex items-center justify-center text-center py-2 h-full mt-3 rounded-[10px]">
                        <span className="text-[20px] text-primary font-bold block group-hover:hidden">
                          {item.title}
                        </span>
                        <span className="text-[17px] text-primary mt-2 hidden group-hover:block">
                          {item.description}
                        </span>
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className="flex items-center justify-center gap-4 mt-3">
                <button
                  className="text-white bg-primary py-2 md:py-1 px-10 text-[12px] md:text-[18px] rounded-[20px] hover:scale-95 transition-all"
                  onClick={() => {
                    // window.open(routes_path?.signup);
                    navigate(routes_path?.contactUs);
                  }}
                >
                  Create Account
                </button>
                <button
                  className="text-primary border-[1px] bg-white text-[12px] md:text-[18px] border-primary py-2 md:py-1 px-10 rounded-[20px] hover:scale-95 transition-all"
                  onClick={() => {
                    navigate(routes_path?.contactUs);
                  }}
                >
                  Contact Sales
                </button>
              </div>
            </div>
          </div>
        </div>

        <IntegrationSection
          page={"neux"}
          color={"#060606"}
          bgColor={"#060606"}
        />
      </div>
      <Footer />
    </>
  );
};

export default NeuX;
