import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import industryBG from "../../assets/images/Industries-images/industy-bg-section.png";
import govermentFlow from "../../assets/video/Govt Flow.mp4";
import hospitalFlow from "../../assets/video/Hospital Flow.mp4";
import payoutFlow from "../../assets/video/Payouts Flow.mp4";
import travelFlow from "../../assets/video/Travel Flow.mp4";
import HOC from "../../component/HOC";
import { routes_path } from "../../config/routesPath";
import { industriesData } from "./industryData";

const Industries = () => {
  const navigate = useNavigate();
  const govtVideoRef = useRef();
  const hospitalVideoRef = useRef();
  const travelVideoRef = useRef();
  const payoutVideoRef = useRef();

  useEffect(() => {
    document.title = "Industries - Touras";
    window.scrollTo({ top: 0, behavior: "smooth" });

    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    const videoElement = govtVideoRef.current;
    const videoElement1 = hospitalVideoRef.current;
    const videoElement2 = travelVideoRef.current;
    const videoElement3 = payoutVideoRef.current;

    if (videoElement || videoElement1 || videoElement2 || videoElement3) {
      videoElement.addEventListener("contextmenu", handleContextMenu);

      return () => {
        // Cleanup the event listener when the component is unmounted
        videoElement.removeEventListener("contextmenu", handleContextMenu);
      };
    }
  }, []);

  return (
    <>
      <div>
        <div className="w-full bg-background grid lg:flex justify-center items-start px-4 gap-10 py-10 lg:px-10 lg:pt-20 font-roboto">
          <div
            className="lg:w-[50%] px-4 lg:pl-10"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <p className="text-[24px] lg:text-[38px] font-bold text-primary">
              Your One Stop Solution for All Government Transactions
            </p>
            <p className="pr-10 text-[14px] lg:text-[18px] py-4 text-[#000000]">
              Flexible, trustworthy, and customised payment solution for your
              business processes
            </p>
            <div className="grid grid-cols-2 gap-4 mt-10 md:mx-auto lg:mx-0 md:max-w-[70%]">
              <button
                className="text-white bg-primary py-1 px-6 lg:text-[18px] rounded-[20px] hover:scale-95 transition-all"
                onClick={() => {
                  // window.open(routes_path?.signup);
                  navigate(routes_path?.contactUs);
                }}
              >
                Sign Up Today
              </button>
              <button
                className="text-secondary border-[1px] lg:text-[18px] border-secondary py-1 px-6 rounded-[20px] hover:scale-95 transition-all"
                onClick={() => navigate(routes_path?.contactUs)}
              >
                Contact Sales
              </button>
            </div>
          </div>
          <div
            className="lg:w-[50%] h-full flex justify-end"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <video
              autoPlay
              loop
              muted
              playsInline
              ref={govtVideoRef}
              className="rounded-[20px]"
            >
              <source src={govermentFlow} type="video/mp4" />
            </video>
          </div>
        </div>

        <div className="w-full grid lg:flex justify-center items-center gap-10 px-4 py-10 lg:px-10 lg:pt-20 font-roboto">
          <div
            className="lg:w-[50%] lg:pl-10"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <p className="text-[24px] lg:text-[38px] font-bold text-primary">
              Simplify Your Healthcare Finances with your solutions
            </p>
            <p className="pr-10 text-[14px] lg:text-[18px] py-4 text-[#000000]">
              Reducing healthcare provider costs, and promoting responsibility
              at every stage of the payment process.
            </p>
            <div className="grid grid-cols-2 gap-4 mt-10 md:mx-auto lg:mx-0 md:max-w-[70%]">
              <button
                className="text-white bg-primary py-1 px-6 lg:text-[18px] rounded-[20px] hover:scale-95 transition-all"
                onClick={() => {
                  // window.open(routes_path?.signup);
                  navigate(routes_path?.contactUs);
                }}
              >
                Sign Up Today
              </button>
              <button
                className="text-secondary border-[1px] lg:text-[18px] border-secondary py-1 px-6 rounded-[20px] hover:scale-95 transition-all"
                onClick={() => navigate(routes_path?.contactUs)}
              >
                Contact Sales
              </button>
            </div>
          </div>
          <div
            className="lg:w-[50%] h-full flex justify-end"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <video
              autoPlay
              loop
              muted
              playsInline
              ref={hospitalVideoRef}
              className="rounded-[20px]"
            >
              <source src={hospitalFlow} type="video/mp4" />
            </video>
          </div>
        </div>

        <div className="w-full bg-background grid lg:flex justify-center items-center gap-10 px-4 py-10 lg:px-10 lg:pt-20 font-roboto">
          <div
            className="lg:w-[50%] h-full flex justify-end"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <video
              autoPlay
              loop
              muted
              playsInline
              ref={travelVideoRef}
              className="rounded-[20px]"
            >
              <source src={travelFlow} type="video/mp4" />
            </video>
          </div>
          <div
            className="lg:w-[50%] lg:pl-10"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <p className="text-[24px] lg:text-[38px] font-bold text-primary">
              Empower Travelers, Simplify Bookings with One Click
            </p>
            <p className="pr-10 text-[14px] lg:text-[18px] py-4 text-[#000000]">
              Smooth Payment Solutions for India's Travel Sector
            </p>
            <div className="grid grid-cols-2 gap-4 mt-10 md:mx-auto lg:mx-0 md:max-w-[70%]">
              <button
                className="text-white bg-primary py-1 px-6 lg:text-[18px] rounded-[20px] hover:scale-95 transition-all"
                onClick={() => {
                  // window.open(routes_path?.signup);
                  navigate(routes_path?.contactUs);
                }}
              >
                Sign Up Today
              </button>
              <button
                className="text-secondary border-[1px] lg:text-[18px] border-secondary py-1 px-6 rounded-[20px] hover:scale-95 transition-all"
                onClick={() => navigate(routes_path?.contactUs)}
              >
                Contact Sales
              </button>
            </div>
          </div>
        </div>

        <div className="px-4 py-10 lg:p-20 flex flex-wrap justify-center gap-4 lg:gap-16">
          {industriesData?.map((item, index) => {
            // if (index === 2) {
            //   return (
            //     <div className="w-full flex justify-center" key={index}>
            //       <div
            //         className="flex flex-col md:flex-row items-center gap-10 bg-backgroundLight p-6 w-[500px] rounded-[20px] cursor-pointer"
            //         onClick={() => navigate(item.navigationUrl)}
            //       >
            //         <div className="bg-white rounded-full p-4 min-w-[100px] h-[100px] flex items-center justify-center border-4 border-primary">
            //           <img src={item.iconUrl} alt={item.title} className="" />
            //         </div>
            //         <div>
            //           <p className="text-primary text-[23px] font-bold">
            //             {item.title}
            //           </p>
            //           <p className="text-[17px] text-[#000000]">
            //             {item.description}
            //           </p>
            //         </div>
            //       </div>
            //     </div>
            //   );
            // } else {
            return (
              <div
                className="flex flex-col md:flex-row items-center gap-10 bg-backgroundLight p-6 w-[500px] rounded-[20px] cursor-pointer"
                key={index}
                onClick={() => navigate(item.navigationUrl)}
              >
                <div className="bg-white rounded-full p-4 min-w-[100px] h-[100px] flex items-center justify-center border-4 border-primary">
                  <img src={item.iconUrl} alt={item.title} className="" />
                </div>
                <div>
                  <p className="text-primary text-[23px] font-bold">
                    {item.title}
                  </p>
                  <p className="text-[17px] text-[#000000]">
                    {item.description}
                  </p>
                </div>
              </div>
            );
            // }
          })}
        </div>

        <div className="flex flex-col md:flex-row gap-10 items-center px-8 md:px-20">
          <div className="md:w-[40%]">
            <p className="text-primary text-[20px] md:text-[24px] lg:text-[35px] font-bold text-center md:text-left">
              Check out how Payouts work for you to upscale your business
            </p>
          </div>
          <div className="pb-10 md:pb-20 h-[200px] md:h-auto flex justify-center md:w-[60%]">
            <div className="relative video-effect">
              <video
                autoPlay
                loop
                muted
                playsInline
                ref={payoutVideoRef}
                className="h-[150px] md:h-auto rounded-[20px]"
              >
                <source src={payoutFlow} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>

        <div className="relative h-[250px] md:h-[300px] flex">
          <img
            src={industryBG}
            alt="industries"
            className="object-cover w-full"
          />
          <p className="lg:text-[40px] md:text-[30px] text-[24px] text-primary font-bold absolute top-10 left-[50%] translate-x-[-50%] z-[5] w-full text-center px-4 lg:w-max">
            Contact Us - Start Collecting Your Dues
          </p>
          <button
            className="text-white absolute bottom-14 md:bottom-16 lg:bottom-24 left-[50%] translate-x-[-50%] bg-primary py-2 px-10 font-medium lg:text-[25px] w-max rounded-full"
            onClick={() => navigate(routes_path?.contactUs)}
          >
            Get Started
          </button>
        </div>
      </div>
    </>
  );
};

export default HOC(Industries);
