import clsx from "clsx";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import growBusiness from "../../assets/images/Products-images/pa_grow-business-bg.png";
import payUPI from "../../assets/images/Products-images/pa_upi.png";
import invoice from "../../assets/images/Products-images/pay_agg-invoice.png";
import check from "../../assets/social-icons/checked-blue.png";
import HOC from "../../component/HOC";
import IntegrationSection from "../../component/IntegrationSection";
import { routes_path } from "../../config/routesPath";
import {
  paGrowBusinessData,
  payAggregatorData_two,
  paymentAggregatorData,
} from "./productDetailsData";

const PaymentAggregator = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Payment Aggregator - Touras";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="overflow-x-hidden">
        <div className="pay-aggregator-bg flex flex-col-reverse lg:justify-center items-start text-white px-4 lg:px-20 py-10 lg:py-0 font-MyriadPro">
          <div className="lg:w-[60%]">
            <p className="text-[24px] lg:text-[50px] font-extrabold text-primary">
              Unlock Growth with Touras's Payment Solutions
            </p>
            <p className="font-normal text-[#020202] max-w-[90%] text-[14px] lg:text-[18px] py-4">
              Take your business to the next level with Touras's payment
              aggregation platform and unlock the world of global growth with
              comprehensive payment solutions.
            </p>
            <div className="grid grid-cols-2 gap-4 lg:max-w-[70%]">
              <button
                className="text-white bg-primary py-2 px-6 text-[12px] lg:text-[18px] rounded-[20px] hover:scale-95 transition-all"
                onClick={() =>
                  navigate(routes_path?.contactUs)
                  // window.open(routes_path?.paymentAggregatorSignup)
                }
              >
                Create Account
              </button>
              <button
                className="text-secondary border-[1px] bg-white text-[12px] lg:text-[18px] border-secondary py-2 px-6 rounded-[20px] hover:scale-95 transition-all"
                onClick={() => navigate(routes_path?.contactUs)}
              >
                Contact Sales
              </button>
            </div>
          </div>
        </div>

        <div className="py-10">
          <p className="text-[24px] lg:text-[38px] text-primary font-MyriadPro font-bold text-center">
            Recast the way you receive payments
          </p>

          <div className="lg:mt-20 mx-4 lg:mx-20">
            {paymentAggregatorData?.map((item, index) => {
              return (
                <div
                  key={index}
                  data-aos={
                    item.img_direction === "left" ? "fade-right" : "fade-left"
                  }
                  data-aos-duration="1000"
                  className={clsx(
                    "flex h-full py-16 px-8 lg:px-20 rounded-[30px] items-center",
                    item.img_direction === "left"
                      ? "flex-col lg:flex-row"
                      : "flex-col lg:flex-row-reverse bg-backgroundLight"
                  )}
                >
                  <div className="lg:w-[50%]">
                    <img
                      src={item?.imageUrl}
                      alt={item?.title}
                      className="min-w-[250px] w-auto h-auto mx-auto pb-8 lg:pb-0"
                    />
                  </div>
                  <div className="lg:w-[50%]">
                    <div className="flex flex-col gap-6">
                      <p className="text-[20px] lg:text-[30px] font-bold text-primary">
                        {item?.title}
                      </p>
                      <p className="text-[18px] lg:text-[23px] font-normal text-[#000000]">
                        {item?.description}
                      </p>
                      <button
                        className="border-[1px] border-secondary rounded-[30px] bg-white text-secondary w-max py-2 px-8"
                        onClick={() => {
                          // window.open(item?.path, "_blank");
                          navigate(item?.path);
                        }}
                      >
                        {item?.buttonTxt}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="py-10 grid grid-cols-2 lg:grid-cols-3 mx-4 lg:mx-40 gap-4 lg:gap-10">
          {payAggregatorData_two?.map((item, index) => {
            return (
              <div
                key={index}
                className="bg-backgroundLight shadow-[0px_0px_4px_0px_#00000040] px-2 py-10 rounded-[20px] text-center group h-[300px] flex flex-col items-center justify-center"
              >
                <img
                  src={item?.iconUrl}
                  alt={item?.title}
                  className="mx-auto w-[50px] lg:w-auto group-hover:hidden block"
                />
                <p className="text-[20px] lg:text-[30px] text-primary font-bold mt-4 group-hover:hidden block">
                  {item?.title}
                </p>
                <p className="text-[14px] lg:text-[18px] text-primary group-hover:block hidden p-4">
                  {item?.description}
                </p>
              </div>
            );
          })}
        </div>

        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          className={clsx(
            "flex h-full py-16 px-8 lg:px-20 mx-4 lg:mx-20 rounded-[30px] items-center bg-backgroundLight flex-col lg:flex-row"
          )}
        >
          <div className="lg:w-[50%]">
            <img
              src={invoice}
              alt="Invoicing"
              className="min-w-[250px] w-auto h-auto mx-auto pb-8 lg:pb-0"
            />
          </div>
          <div className="lg:w-[50%]">
            <div className="flex flex-col gap-6">
              <p className="text-[20px] lg:text-[30px] font-bold text-primary">
                Invoicing
              </p>
              <p className="tesxt-[18px] lg:text-[23px] font-normal text-[#000000]">
                Set reminders for timely collection, effortless invoicing by
                sharing links via SMS, Email, social media etc.
              </p>
              <button
                className="border-[1px] border-secondary rounded-[30px] bg-white text-secondary w-max py-2 px-8"
                onClick={() => {
                  navigate(routes_path?.contactUs);
                }}
              >
                Contact Sales
              </button>
            </div>
          </div>
        </div>

        <div className="pt-10 pb-40 mt-20 lg:px-20 pa_gb_bg">
          <p className="text-primary text-[24px] px-4 lg:text-[40px] text-center lg:text-left font-bold max-w-[35rem] lg:px-10">
            Grow your business with Touras
          </p>

          <img
            src={growBusiness}
            alt="grow business1"
            className="block md:hidden"
          />

          <div className="grid lg:grid-cols-3 gap-6 lg:gap-10 px-4 lg:px-0 mt-10 m-4 lg:m-10 lg:max-w-[75%]">
            {paGrowBusinessData?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={clsx(
                    "gap-2 items-start",
                    item?.title !== "" ? "" : "hidden lg:flex lg:invisible"
                  )}
                >
                  <img src={check} alt={item.title} width="30" />
                  <div className="text-[17px] text-primary">
                    <p className="font-bold mb-2">{item?.title}</p>
                    <p>{item?.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="payment-aggregator-bg pt-[30px!important] pb-[0px!important] lg:py-[30px!important] relative">
          <div className="flex flex-col-reverse lg:flex-row gap-10 m-4 lg:m-10 px-4` lg:px-20">
            <div className="flex items-center justify-center gap-6 lg:w-[40%]">
              <img
                src={payUPI}
                alt="pay aggregator UPI"
                className="lg:absolute bottom-0"
              />
            </div>
            <div className="lg:w-[60%] text-center">
              <p className="text-[24px] lg:text-[40px] font-bold text-[#FFFFFF]">
                Encourage UPI payments with Touras
              </p>
              <p className="text-[#FFFFFF] text-[16px] lg:text-[18px] py-4">
                Touras simplifies accepting payments via UPI apps at checkout.
                Customize payment flows to fit your business needs. Start with
                Touras now!
              </p>
              <div className="flex gap-4 justify-evenly mt-4">
                <button
                  className="text-white bg-primary py-2 px-10 text-[12px] lg:text-[18px] rounded-[20px] hover:scale-95 transition-all"
                  onClick={() =>
                    // window.open(routes_path?.paymentAggregatorSignup)
                    navigate(routes_path?.contactUs)
                  }
                >
                  I’m Ready!
                </button>
                <button
                  className="text-secondary border-[1px] bg-white text-[12px] lg:text-[18px] border-secondary py-2 px-10 rounded-[20px] hover:scale-95 transition-all"
                  onClick={() => {
                    navigate(routes_path?.contactUs);
                  }}
                >
                  Talk to Sales
                </button>
              </div>
            </div>
          </div>
        </div>

        <IntegrationSection
          page={"payment-aggregator"}
          signupLink={routes_path?.paymentAggregatorSignup}
        />
      </div>
    </>
  );
};

export default HOC(PaymentAggregator);
